import { useSelector } from '@innedit/formidable';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Button from '../../components/Button';
import { ReducersProps } from '../../reducers';
import TemplateUser from '../../templates/user';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageUtilisateurEspaces: FC<PageProps & UserProps> = () => {
  const espaces = useSelector((state: ReducersProps) => state.cms.espaces);

  return (
    <TemplateUser title="Mes espaces">
      {espaces && (
        <ul>
          {espaces.map(espace => (
            <li key={espace.id}>
              <Button to={`/espaces/${espace.id}/dashboard/`} variant="link">
                {espace.name}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </TemplateUser>
  );
};

export default requireUser(PageUtilisateurEspaces);
